<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-header height="auto">
        <div class="fr">
          <el-button
            type="primary"
            :icon="CirclePlus"
            @click="editBanner()"
          >添加轮播图</el-button>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="轮播图">
            <template #default="{ row }">
              <el-image
                v-if="row.image && row.image.url"
                class="icon-img"
                :src="row.image.url"
              ></el-image>
              {{ row.title }}
            </template>
          </el-table-column>
          <el-table-column label="描述" prop="content"></el-table-column>
          <el-table-column label="状态" align="center" width="58">
            <template #default="{ row }">
              <el-tag v-if="row.state === 1" type="success">启用</el-tag>
              <el-tag v-else type="info">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="118">
            <template #default="{ row }">
              <el-button
                type="text"
                :icon="Edit"
                @click="editBanner(row.id)"
              >编辑</el-button>
              <el-button
                type="text"
                class="color-danger"
                :icon="Delete"
                @click="remove(row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { ElMessageBox } from 'element-plus';
import { CirclePlus, Edit, Delete } from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import EditDialog from './edit.vue';
import request from '@/hooks/request';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/banner', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const editDialog = ref();
function editBanner(bannerId) {
  editDialog.value.open(bannerId);
}

function remove(banner) {
  ElMessageBox.confirm(`删除轮播图：${banner.title}，是否继续？`, '提示', {
    type: 'warning',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(() => {
    request.delete(`/admin/banner/${banner.id}`).then(() => {
      getList();
    });
  });
}

onMounted(() => {
  getList();
});
</script>
